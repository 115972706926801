import * as yup from "yup";
import moment from 'moment';
import axios from "axios";
const validationsForm =  yup.object().shape({
  new_name: yup
    .string().required("Required"),
  emailaddress: yup
    .string()
    .email("Email address is not valid")
    .required("Required"),
  new_rmecode: yup
  .string()
  .required("Required"),
  new_lastworkingday: yup.
  string()
  .required("Required"),
  gtoken: yup.
  string()
  .required("Required"),

});

export default validationsForm;
