import React from 'react'
import Header from '../Element/Header'
import { Box, Container, Grid, TextField } from '@mui/material'
import './index.css'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import axios from 'axios';
import { useFormik } from 'formik'
import validationsForm from './ValidationSchema'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs';
import { Helmet } from 'react-helmet';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import LoadingButton from '@mui/lab/LoadingButton';
function ExperienceLetter({page_title}) {
    const [LoadingAPI,setLoadingAPI]= React.useState(false);
    const recaptchaRef = React.useRef(null);
    const MySwal = withReactContent(Swal);
    const formik = useFormik({
        initialValues: {
        new_name: '',
        emailaddress:'',
        new_rmecode:'',
        new_lastworkingday:"",
        gtoken:""
        },
        validationSchema: validationsForm,
        onSubmit: values => {
          const {new_lastworkingday,...rest}=values

            axios.post(`${process.env.REACT_APP_Back_URL}/experience-letter-request`,{
              "new_lastworkingday":  dayjs(new_lastworkingday).format('YYYY-MM-DD'),
              ...rest
            }
                ).then(resp=>{
                  setLoadingAPI(false)
                  recaptchaRef.current?.reset();
                  formik.setFieldValue("gtoken",null)
                if(resp.status==200){    
                  MySwal.fire({
                    // position: 'top-end',
                    icon: 'success',
                    title: 'Submitted Successfully',
                    html: `<div style="direction:rtl;">
                    عزيزي المتقدم،<br>
                    شكرًا لطلبك إصدار شهادة خبرة. نود إعلامك بأن طلبك قد تم استلامه، وسيتم إبلاغك عبر البريد الإلكتروني بمجرد الانتهاء من معالجة طلبك.<br>
                    نرجو منك متابعة بريدك الإلكتروني بشكل منتظم، بما في ذلك مجلد Junk/Spam، حيث ستتلقى رسالة منّا تفيد بجاهزية طلبك وتدعوكم للحضور لاستلام الشهادة.<br>
                    شكرًا لتعاونك.
                    </div>
                    <br><br>
                    Dear Applicant,<br>
                    Thank you for your request to issue an experience certificate. We would like to inform you that your request has been received, and you will be notified via email once your request has been processed.<br>
                    Please make sure to check your email inbox regularly, including the Junk/Spam folder, as you will receive a message from us confirming that your request is ready and inviting you to collect the certificate.<br>
                    Thank you for your cooperation.`,
                    showConfirmButton: true,
                    // timer: 1500
                  }).then( (result) => {
                    if (result.isConfirmed) {
                      setTimeout(()=> window.location.href ="https://rowad-rme.com",1500) 
                    } 
                  } 
                  
                  )
                }
                }
                )      
                .catch(function (error) {  
                  setLoadingAPI(false)
                  recaptchaRef.current?.reset(); 
                  MySwal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: error.message
         
                  });
                 })
 
        },
      });
      
  return (
    <>
        <Helmet>
            <title>{page_title}</title>
            {/* <meta name="description" content="test on react-helmet" />
            <meta name="theme-color" content="#ccc" /> */}
        </Helmet>
        <Container maxWidth="sm" style={{ paddingTop:'10vh'}}>     
            <Box sx={{ bgcolor: 'rgb(255 255 255)', textAlign: 'left', padding: '30px' }}>
            <Header page_title={page_title}/>
            <form style={{ flexWrap: 'center' }}>
                <Grid container spacing={2} style={{  justifyContent: 'center' }}>
                    <Grid item xs={11} sm={10}>
                        <TextField
                        required
                        
                        name="new_name"
                        id="new_name"
                        // autoFocus 
                        inputProps={{
                        maxlength: 120
                        }}
                        helperText={formik.touched.new_name && formik.errors.new_name}
                        error={ formik.touched.new_name  && Boolean(formik.errors.new_name)}
                        label="Full Name"
                        value={formik.values.new_name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        fullWidth
                        margin="normal"
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{  justifyContent: 'center' }}>
                    <Grid item xs={11} sm={10}>
                        <TextField 
                            required
                            name="emailaddress"
                            id="emailaddress"
                            inputProps={{
                                maxlength: 100
                            }}
                            // autoFocus 
                            helperText={formik.touched.emailaddress && formik.errors.emailaddress}
                            error={ formik.touched.emailaddress  && Boolean(formik.errors.emailaddress)}
                            label="Email"
                            value={formik.values.emailaddress}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            fullWidth
                            margin="normal"
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{  justifyContent: 'center' }}>
                    <Grid item xs={11} sm={10}>
                        <TextField 
                            type="text"
                            name="new_rmecode"
                            label="RME Code"
                            value={formik.values.new_rmecode}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            inputProps={{
                            maxlength: 100
                            }}
                            helperText={formik.touched.new_rmecode && formik.errors.new_rmecode}
                            error={ formik.touched.new_rmecode  && Boolean(formik.errors.new_rmecode)}
                            margin="normal"
                            fullWidth
                            required
                        />
                    </Grid>
    
 
                </Grid>
                <Grid container spacing={2} style={{  justifyContent: 'center' }}>

                </Grid>
                <Grid container spacing={2} style={{  justifyContent: 'center' }}>
                <Grid item xs={11} sm={10}> 
                      <LocalizationProvider  dateAdapter={AdapterDayjs} >
                        <DemoContainer  components={['DatePicker']} >
                          <DatePicker
                          label="Last Working Day"
                          maxDate={dayjs()}
                           margin="normal"
                           className="css-17vbkzs-MuiFormControl-root-MuiTextField-root"
                          value={ formik.values.new_lastworkingday || null}
                           onChange={(date) => formik.setFieldValue('new_lastworkingday',  date )}
                          format="YYYY-MM-DD"
                          slotProps={{
                            textField: {
                              // format:'YYYY-MM-DD',
                              // disabled:true,
                              fullWidth: true,
                              required:true,
                              error: formik.touched.new_lastworkingday  && Boolean(formik.errors.new_lastworkingday),
                              helperText:formik.touched.new_lastworkingday && formik.errors.new_lastworkingday
                            },
                            }}
                            />
                          </DemoContainer>
                      </LocalizationProvider>
                      </Grid>
                  </Grid>
                <Grid container spacing={2}  style={{  justifyContent: 'center' }}>
                  <Grid item xs={11} sm={10}>
                  <HCaptcha
                          sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                          helperText={formik.touched.new_contactphone && formik.errors.new_contactphone}
                          error={ formik.touched.new_contactphone  && Boolean(formik.errors.new_contactphone)} 
                          onVerify={(value) => {      
                            formik.setFieldValue("gtoken", value);
                            formik.setSubmitting(false);
                          }}
                          onExpire={()=>formik.setFieldValue("gtoken", "")}
                          />
                               <>
                               <div className='Error'>{formik.errors.gtoken}</div> </>
                  </Grid>
                </Grid>
                <Grid container spacing={2}  style={{  justifyContent: 'center' }}>
                  <Grid item xs={11} sm={10}>
                  <LoadingButton variant="contained"
                      loading={LoadingAPI}
                  onClick={()=>formik.handleSubmit()} >
                    Submit
                  </LoadingButton>
     
    
          
                  </Grid>
                </Grid>

            </form>
            </Box>
        </Container>
    </>
  )
}

export default ExperienceLetter